/* eslint-disable no-undef */
import React from "react"
import TemplatePageMarketing from "@components/pageCasosdeUso/pageAlternativaMarketing/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>La plataforma omnicanal de telemarketing [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/plataforma-telemarketing/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce nuestra plataforma omnicanal de telemarketing donde podrás integrar todos tus canales de atención y sistemas internos."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="La plataforma omnicanal de telemarketing"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/plataforma-telemarketing/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Conoce nuestra plataforma omnicanal de telemarketing donde podrás integrar todos tus canales de atención y sistemas internos."
      />
    </Helmet>
    <TemplatePageMarketing location={location} />
  </div>
)

export default IndexPage
