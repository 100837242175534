import React from "react"

const Question = ({ data }) => {
  return (
    <section className="container-question-alternative-marketing">
      <div className="title-question-pages-relative">{data.title}</div>
      <section className="intro-question-pages-relative">
        <section>
          <img
            src={data.image}
            alt=""
            className="intro-question-img-pages-relative"
          />
        </section>
        <div>{data.textbody}</div>
      </section>
    </section>
  )
}

export default Question

