/* eslint-disable no-undef */
import React from "react"
import data from "@components/pageCasosdeUso/pageAlternativaMarketing/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import educacion from "@components/sharedComponents/testimonies/images/iconos/educacion.svg"
import gas from "@components/sharedComponents/testimonies/images/iconos/gas.svg"
import Integrations from "@components/pageScore/integrations/integrations"
import SilderPricing from "@components/pageScore/pageContactCenterOmnicanal/components/silderPricing/silderPricing"
import dataPricing from "@components/pageScore/pageContactCenterOmnicanal/data"
import UseCases from "@components/pageScore/pageContactCenterOmnicanal/components/useCases/useCases"
import TitleUnderlineTestimony from "@components/pageCasosdeUso/pageMarketing/titleUnderline/titleUnderlineTestimony"
import Question from "@components/pageCasosdeUso/pageAlternativaMarketing/components/questions"
import UseScore from "@components/pageCasosdeUso/pageAlternativaMarketing/components/useCards"
import Start from "@components/pageCasosdeUso/pageAlternativaMarketing/components/start"

const TemplatePageAtencion = ({ location }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

const iconsSilder = i => {
  switch (i) {
    case 0:
      return ecommerce
    case 1:
      return concesionarias
    case 2:
      return finanzas
    case 3:
      return educacion
    case 4:
      return gas
    default:
      return ""
  }
}
const titleSilder = i => {
  switch (i) {
    case 0:
      return "Ecommerce"
    case 1:
      return "Concesionarias"
    case 2:
      return "Finanzas"
    case 3:
      return "Educación"
    case 4:
      return "Consumo"
    default:
      return "Conoce otros casos de éxito"
  }
}
const titleTestimony = <p>
Beex ha mejorado la experiencia de compra de empresas en diferentes sectores, conoce <TitleUnderlineTestimony underline="los resultados" />
</p>


  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <div className="banner-invisible-ebook" />
        <div>
          <Header
            path="/"
            location={location}
            bannerCTA={true}
            windowsWidth={windowsWidth}
          />
        </div>
        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title=" Ellos han logrado una experiencia de compra más personalizada que les permitió fidelizar mejor" />
          <Question data={data.question} location={location} />
          <UseCases
            data={data.modules}
            location={location}
            data1={data.useCases}
            alternative={true}
          />
          <BannerCTA
            title="¡Automatizamos tus procesos de telemarketing!"
            text="Te ofrecemos una solución que garantiza buenos resultados en tus campañas."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />

          <UseScore data={data.useScore} />
          <SilderPricing data={dataPricing.pricing} alternative={true} />
          <Integrations location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageAtencion
